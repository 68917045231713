<script setup lang="ts">
import HtmlBlock from '@/components/shared/HtmlBlock.vue'
import Table from '@/components/shared/Table.vue'
import useCampaigns from '@/composables/useCampaigns'
import { watch, ref, onBeforeMount, onMounted, onBeforeUnmount, computed } from 'vue'
import { useHead } from '@unhead/vue'
import { useRoute } from 'vue-router'
import { type Payment } from '@/types/payment'
import { useConvert24HourTime } from '@/composables/useConvertToTime'
import { useProfileStore } from '@/stores/profile'
import { useLayoutStore } from '@/stores/layout'
import useQualifications from '@/composables/useQualifications'
import { extractContentByStatus } from '@/composables/utils'
import QualificationProgressChart from '@/components/shared/QualificationProgressChart.vue'
import useHelpers from '@/composables/useHelpers'
import WIZZYWIGEditor from '@/components/shared/WIZZYWIGEditor.vue'
import type { Qualification, QualificationDetails } from '@/types/qualification'
import { useCampaignStore } from '@/stores/campaigns'

const alert = ref(true)

const { setLoadingState } = useLayoutStore()
const { fetchCampaignQualifications, fetchLearnWorldsCourse, campaignQualificationList } = useQualifications()
const campaignStore = useCampaignStore()
const { dateWithoutTimezone } = useHelpers()
const profileStore = useProfileStore()
const loading = ref(true)
const loadingWizzywigContent = ref(false)
const courseURL = ref()
const qualificationProgress = ref()
const route = useRoute()
const {
  getAgentCampaignDetails,
  campaignDetails,
  getCampaignVariables,
  campaignVariables,
  getCampaignPayroll,
  campaignPayroll,
  saveCampaignContentBlocks,
  showHideActiveCampaignContent,
  campaignProperties
} = useCampaigns()
const showNotification = ref(false)


const payweekHours = computed(() => {
  return campaignPayroll.value.reduce((acc: number, curr: Payment) => {
    return acc + curr.hours
  }, 0)
})
const payweekPay = computed(() => {
  return campaignPayroll.value.reduce((acc, curr: Payment) => {
    return acc + parseFloat(curr.amount)
  }, 0)
})

const contentBlocks = computed(() => {
  const htmlContent = campaignDetails.value?.wysiwyg_description ?? ''
  const currentAgentStatus = campaignDetails.value?.agent_status.name || ''
  const extractedContent = extractContentByStatus(htmlContent, currentAgentStatus);

  return extractedContent
})

const campaignQualifications = computed(() => {
  const filteredCampaignQualificationsByAgentStatusGroup = campaignDetails.value?.agent_status.status_group === 1 ? campaignQualificationList.value.filter(q => q.qualification.type !== 7) : campaignQualificationList.value
  const campaignQualificationsList = profileStore.getProfile.groups.includes('Admins') ? filteredCampaignQualificationsByAgentStatusGroup : filteredCampaignQualificationsByAgentStatusGroup.filter((q: any) => ![1, 2, 3].includes(q.qualification.type))
  return campaignQualificationsList
});

const agentQualifications = computed(() => {
  const qualificationsPassed = profileStore.getProfile.agent?.qualifications?.filter((q: any) => q.is_qualified)
  return qualificationsPassed?.length ? qualificationsPassed.map((q: any) => q.qualification.id) : null
})

const qualificationsMissing = computed(() => {
  const passedQualifications = profileStore.getProfile.agent?.qualifications?.filter((q: any) => q.is_qualified === true)
  if (!agentQualifications.value?.length || !campaignQualifications.value?.length) return campaignQualifications.value.length
  const matchedQualifications = campaignQualifications.value.filter((q: any) => passedQualifications.some((p: any) => p.qualification.id === q.qualification.id))
  return campaignQualifications.value.length - matchedQualifications.length
})

const chartPercentage = computed(() => {
  const passedQualifications = profileStore.getProfile.agent?.qualifications?.filter((q: any) => q.is_qualified === true)
  if (!passedQualifications?.length || !campaignQualifications.value?.length) return 0
  const matchedQualifications = campaignQualifications.value.filter((q: any) => passedQualifications.some((p: any) => p.qualification.id === q.qualification.id))
  return (matchedQualifications.length / campaignQualifications.value.length * 100).toFixed(0)
})

const isAdminRole = computed(() => {
  return profileStore.getProfile.groups.includes('Admins')
})

async function getCurrentCampaignData() {
  const campaignDetailsPromise = async () => await getAgentCampaignDetails(route.params?.id as string)
  const campaignVariablesPromise = async () => await getCampaignVariables(route.params?.id as string)
  const campaignPayrollPromise = async () => await getCampaignPayroll(route.params?.id as string)
  await Promise.allSettled([campaignDetailsPromise(), campaignVariablesPromise(), campaignPayrollPromise()])
  loading.value = false
}

function subtractDays(dateString: string | number | Date, days: number) {
  const noTimezoneDate = dateWithoutTimezone(dateString)
  noTimezoneDate.setDate(noTimezoneDate.getDate() - days)
  return noTimezoneDate
}

const shyftDays = computed(() => {
  let days = []
  if (campaignDetails.value?.is_monday_shift) days.push('Mon')
  if (campaignDetails.value?.is_tuesday_shift) days.push('Tue')
  if (campaignDetails.value?.is_wednesday_shift) days.push('Wed')
  if (campaignDetails.value?.is_thursday_shift) days.push('Thu')
  if (campaignDetails.value?.is_friday_shift) days.push('Fri')
  if (campaignDetails.value?.is_saturday_shift) days.push('Sat')
  if (campaignDetails.value?.is_sunday_shift) days.push('Sun')
  return days.length ? days.join(', ') : null
})

async function saveContentBlock() {
  try {
    if (campaignDetails.value?.id && campaignDetails.value?.wysiwyg_description) {
      loadingWizzywigContent.value = true
      await saveCampaignContentBlocks(campaignDetails.value?.id, campaignDetails.value.wysiwyg_description)
      loadingWizzywigContent.value = false
      showNotification.value = true
    }
  } catch (error) {
    console.error(error)
    if (typeof error === 'object' && error != null) {
      if ('message' in error && typeof error.message === 'string') profileStore.setError(error.message)
    }
    loadingWizzywigContent.value = false
  }

}

function isQualified(id: number) {
  return agentQualifications.value?.includes(id)
}

function certificationProgress(qualificationID: number) {
  const certificationQualification = profileStore.getProfile.agent.qualifications.find((qual: Qualification) => qual?.qualification?.id === qualificationID)

  if (certificationQualification) {
    const { content } = certificationQualification
    return content && content.progress ? content.progress * 100 : 0
  }

  return 0
}

function getQualificationParameter(qualifiaction: QualificationDetails, expresion_key: string) {
  const { parameters } = qualifiaction
  const qualification_parameter_key = Object.keys(parameters).find(key => key.toLowerCase().includes(expresion_key)) || null

  if (qualification_parameter_key) {
    return parameters[qualification_parameter_key]
  }

  return 'No parameter'
}

async function onGoToLearnWorldsCourse(qualificationId: number) {
  const { learnworlds_course_url = null }: any = await fetchLearnWorldsCourse({ id: qualificationId })
  courseURL.value = learnworlds_course_url
  window.open(courseURL.value, '_blank')
}

declare global {
  interface Window {
    onSIApiReady: any;
    SI_API: any
  }
}

onBeforeMount(async () => {
  useHead({
    script: [
      {
        key: 'socialintents1',
        src: 'https://www.socialintents.com/api/socialintents.1.3.js#2c9faa358c852b2a018ca7a3bf250e8b',
        async: true
      }
    ]
  })
  setLoadingState(true)
  await getCurrentCampaignData()
  if (campaignDetails.value?.agent_status.name != null) {
    showHideActiveCampaignContent(campaignDetails.value.agent_status.name)
  }
  if (campaignDetails.value != null) {
    fetchCampaignQualifications({ id: campaignDetails.value.id })
  }
  setLoadingState(false)
})
onBeforeUnmount(() => {
  window.SI_API?.hidePopup()
  window.SI_API?.hideTab()
})
onMounted(() => {
  if (window.SI_API) window.SI_API?.showTab()
  window['onSIApiReady'] = function () {
    window.SI_API?.showTab()
    window.SI_API?.setChatInfo(profileStore.getProfile.agent.name, profileStore.getProfile.agent.email, profileStore.getProfile.agent.phone, '', '')
  }
  setTimeout(() => {
    window.SI_API?.showPopup()
  }, 200)
})

watch(() => route.params.id, async () => {
  setLoadingState(true)
  if (route.params?.id != null) fetchCampaignQualifications({ id: parseInt(route.params.id as string) })
  if (route.params?.id != null) await getCurrentCampaignData()
  setLoadingState(false)
})
</script>

<template>
  <div v-if="qualificationsMissing && !isAdminRole"
    @click="() => { qualificationProgress.scrollIntoView({ behavior: 'smooth' }) }"
    class="tw-fixed tw-top-0 tw-w-full md:tw-w-[calc(100%_-_320px)] xl:tw-w-[calc(100%_-_500px)] tw-bg-shyftoff-pink tw-py-2 tw-z-2 tw-right-0 tw-text-white tw-text-center tw-font-bold tw-cursor-pointer ">
    <span class="tw-inline-flex tw-items-center tw-justify-center tw-rounded-full tw-h-6 tw-w-6 tw-bg-shyftoff-purple">
      {{ qualificationsMissing }}
    </span>
    <span v-if="qualificationsMissing > 1" class="inline-block ml-2">qualifications</span>
    <span v-else class="inline-block ml-2">qualification</span>
    missing. See more.
  </div>
  <div class="tw-px-2 md:tw-px-6 tw-pt-6 tw-pb-4 tw-mx-auto tw-max-w-[1536px] md:!tw-pl-[274px] tw-flex-grow tw-w-full">
    <header v-if="campaignProperties.name"
      class="tw-flex tw-flex-col md:tw-flex-row tw-justify-end xl:tw-justify-between md:tw-mb-0">
      <div class="tw-hidden md:tw-flex tw-w-full tw-items-center tw-justify-center tw-gap-4"
        :class="{ 'tw-mt-8': qualificationsMissing && !isAdminRole }">
        <img v-if="campaignDetails?.logo_url" :src="campaignDetails.logo_url"
          class="!tw-max-h-[70px] tw-max-w-[160px]" />
        <img v-else src="@/assets/images/image-placeholder.svg" width="80" alt="">
        <h1 class="tw-text-regular-text tw-text-[1.35rem] tw-font-black tw-text-center md:tw-text-left">
          {{ campaignDetails?.name }}
        </h1>
      </div>
      <div class="md:tw-hidden" :class="{ 'tw-mt-8': qualificationsMissing && !isAdminRole }">
        <h1
          class="tw-text-shyftoff-purple tw-text-2xl tw-mr-auto tw-font-black tw-w-full tw-text-center md:tw-text-left">
          My Campaigns
        </h1>
        <VBtn size="large" color="#E4D5F9" variant="outlined" rounded="lg"
          class="tw-font-normal tw-normal-case tw-tracking-normal tw-fill-[#AAABB3] tw-grey-border hover:tw-fill-shyftoff-purple hover:!tw-border-[#DECFFA] tw-w-full tw-mt-6">
          <template #prepend>
            <div class="tw-flex tw-justify-center tw-items-center">
              <VImg v-if="campaignDetails?.logo_url" :src="campaignDetails.logo_url" aspect-ratio="1/1" :cover="true"
                class="tw-w-12 tw-h-8 "></VImg>
              <img v-else src="@/assets/images/image-placeholder.svg" width="80" alt="">
            </div>
          </template>
          <template #default>
            <span class="tw-text-regular-text tw-font-extrabold tw-normal-case tw-tracking-normal tw-text-left">
              {{ campaignDetails?.name }}
            </span>
            <v-menu activator="parent" location="bottom" :min-width="250" width="250">
              <v-list>
                <v-list-item v-for="campaign in campaignStore.activeCampaigns" :key="campaign.id" :value="campaign.id">
                  <v-list-item-title>
                    <RouterLink :to="{ name: 'ActiveCampaignDetails', params: { id: campaign.id } }"
                      class="tw-flex tw-items-center tw-gap-3 tw-py-2 tw-text-regular-text">
                      <!-- <div class="tw-flex tw-items-center">
                          <div class="tw-w-[42px] tw-h-8">
                            <div class="tw-flex tw-justify-center tw-items-center tw-object-cover">
                              <VImg v-if="campaign?.logo_url" :src="campaign.logo_url" aspect-ratio="1/1" :cover="true" class="tw-w-12 tw-h-8 "></VImg>
                              <img v-else src="@/assets/images/image-placeholder.svg" width="80" alt="">
                            </div>
                          </div>
                        </div> -->
                      <div>
                        {{ campaign.name }}
                      </div>
                    </RouterLink>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <template #append>
            <svg width="12" height="15" viewBox="0 0 12 15" xmlns="http://www.w3.org/2000/svg" class="tw-grow-0">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M6 0L12 6L0 6L6 0ZM6 15L0 9H12L6 15Z" />
            </svg>
          </template>
        </VBtn>
      </div>

    </header>

    <div v-if="
      shyftDays ||
      (campaignDetails?.shift_hours_from && campaignDetails?.shift_hours_to) ||
      (campaignDetails?.min_hourly_pay && campaignDetails?.max_hourly_pay) ||
      campaignVariables.length
    " class="tw-pt-4 tw-mb-8">
      <ul class="tw-flex tw-flex-wrap tw-gap-4 tw-text-center">
        <li v-if="shyftDays != null && campaignProperties.shift_days"
          class="tw-border tw-border-solid tw-border-[#E6E6E6] tw-flex tw-flex-row tw-items-center tw-rounded-lg tw-rounded-l-none tw-justify-between tw-py-3 tw-px-6 tw-space-y-1 tw-border-l-[6px] tw-border-l-shyftoff-purple tw-flex-grow-0 tw-flex-shrink-0 tw-col-span-6 tw-max-w-full tw-flex-wrap tw-gap-x-4 tw-gap-y-1">
          <span class="tw-text-shyftoff-purple ">
            Shift Days
          </span>
          <span class="tw-font-extrabold tw-grow-0 tw-tracking-wide tw-text-left !tw-mt-0">
            {{ shyftDays }}
          </span>
        </li>
        <li
          v-if="campaignDetails?.shift_hours_from && campaignDetails?.shift_hours_to && campaignProperties.shift_hours"
          class="tw-border tw-border-solid tw-border-[#E6E6E6] tw-flex tw-flex-row tw-items-center tw-rounded-lg tw-rounded-l-none tw-justify-between tw-py-3 tw-px-6 tw-space-y-1 tw-border-l-[6px] tw-border-l-shyftoff-purple  tw-flex-grow-0 tw-flex-shrink-0 tw-col-span-6 tw-max-w-full tw-flex-wrap tw-gap-x-4 tw-gap-y-1">
          <span class="tw-text-shyftoff-purple ">
            Shift Hours
          </span>
          <span class="tw-font-extrabold tw-grow-0 tw-tracking-wide !tw-mt-0">
            {{ useConvert24HourTime(campaignDetails?.shift_hours_from) }} - {{
              useConvert24HourTime(campaignDetails?.shift_hours_to) }}
          </span>
        </li>
        <li
          v-if="campaignDetails?.min_hourly_pay && campaignDetails?.max_hourly_pay && campaignProperties.min_hourly_pay && campaignProperties.max_hourly_pay"
          class="tw-border tw-border-solid tw-border-[#E6E6E6] tw-flex tw-flex-row tw-items-center tw-rounded-lg tw-rounded-l-none tw-justify-between tw-py-3 tw-px-6 tw-space-y-1 tw-border-l-[6px] tw-border-l-shyftoff-purple tw-flex-grow-0 tw-flex-shrink-0 tw-col-span-6 tw-max-w-full tw-flex-wrap tw-gap-x-4 tw-gap-y-1">
          <span class="tw-text-shyftoff-purple ">
            Base Agent Pay
          </span>
          <span class="tw-font-extrabold tw-grow-0 tw-tracking-wide !tw-mt-0">
            ${{ campaignDetails?.min_hourly_pay }} - ${{ campaignDetails?.max_hourly_pay }}
          </span>
        </li>
        <li v-if="campaignDetails?.training_bonus && campaignProperties.training_bonus"
          class="tw-border tw-border-solid tw-border-[#E6E6E6] tw-flex tw-flex-row tw-items-center tw-rounded-lg tw-rounded-l-none tw-justify-between tw-py-3 tw-px-6 tw-space-y-1 tw-border-l-[6px] tw-border-l-shyftoff-purple tw-flex-grow-0 tw-flex-shrink-0 tw-col-span-6 tw-max-w-full tw-flex-wrap tw-gap-x-4 tw-gap-y-1">
          <span class="tw-text-shyftoff-purple ">
            Certification Bonus
          </span>
          <span class="tw-font-extrabold tw-grow-0 tw-tracking-wide !tw-mt-0">
            ${{ campaignDetails?.training_bonus }}
          </span>
        </li>
        <template v-for="variable in campaignVariables" :key="variable.code">
          <li v-if="variable.value != null"
            class="tw-border tw-border-solid tw-border-[#E6E6E6] tw-flex tw-flex-row tw-items-center tw-rounded-lg tw-rounded-l-none tw-justify-between tw-py-3 tw-px-6 tw-space-y-1 tw-border-l-[6px] tw-border-l-shyftoff-purple tw-flex-grow-0 tw-flex-shrink-0 tw-col-span-6 tw-max-w-full tw-flex-wrap tw-gap-x-4 tw-gap-y-1"
            :class="{ '2xl:tw-col-span-6': campaignVariables.length > 2 }">
            <span class="tw-text-shyftoff-purple tw-shrink-0">
              {{ variable.name }}
            </span>
            <span class="tw-font-extrabold tw-grow-0 tw-tracking-wide !tw-mt-0">
              {{ variable.value }}
            </span>
          </li>
        </template>
      </ul>
    </div>
    <div>

      <div v-if="campaignQualifications?.length" class="tw-mb-8" ref="qualificationProgress">

        <div
          class="tw-flex tw-flex-col xl:tw-flex-row lg:tw-items-center tw-relative lg:tw-pl-[170px] tw-overflow-hidden tw-pt-[1.25rem] tw-pb-[1.1rem] tw-text-center lg:tw-text-left">

          <QualificationProgressChart :series="chartPercentage"
            class="tw-w-[200px] tw-overflow-hidden tw-grow-0 tw-bg-white lg:tw-absolute -tw-left-4 -tw-mt-12 -tw-mb-14 md:tw-mt-4 xl:tw-mt-8 lg:tw-mb-0 tw-h-[calc(100%_-_40px)]">
          </QualificationProgressChart>

          <div
            class="tw-w-full tw-grow-0 tw-shrink-0 before:tw-block  before:tw-text-shyftoff-purple before:tw-absolute before:tw-left-2 before:tw-top-0 before:tw-w-[170px] before:tw-h-[20px] before:tw-text-[15px] before:tw-text-center before:-tw-translate-y-1/2">
            <h3 class="tw-font-extrabold tw-mb-4 tw-relative tw-pl-4 tw-text-shyftoff-purple">
              Onboarding Progress
            </h3>

            <div
              class="tw-bg-[linear-gradient(90deg,_#575EE7_3.71%,_#EF74C0_95.38%)] tw-p-[1px] tw-rounded-[9px] lg:tw-rounded-l-none">
              <div class="!tw-bg-white tw-rounded-[8px] lg:tw-rounded-l-none tw-relative tw-py-5 tw-text-left">
                <div
                  class="tw-hidden tw-items-center tw-absolute tw-bottom-[10px] tw-text-left tw-translate-y-full tw-bg-white tw-px-4 tw-leading-none tw-left-1/2 -tw-translate-x-1/2 lg:tw-flex lg:tw-translate-x-0 lg:tw-left-0">
                  <div v-if="qualificationsMissing" class=" tw-flex tw-gap-x-3 tw-items-center">
                    <div class="tw-text-sm tw-text-[#6B7280]">
                      <span>Required Qualifications:</span>
                    </div>

                    <div>
                      <svg class="tw-inline-block tw-align-middle" width="14" height="14" viewBox="0 0 17 17"
                        fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M17 8.5C17 13.1944 13.1944 17 8.5 17C3.80558 17 0 13.1944 0 8.5C0 3.80558 3.80558 0 8.5 0C13.1944 0 17 3.80558 17 8.5ZM8.49999 10.2C8.19217 10.2 7.8963 10.0809 7.67435 9.86758C7.4524 9.65429 7.32159 9.36339 7.30932 9.05582L7.15227 5.09004C7.14597 4.92974 7.1721 4.76981 7.2291 4.61985C7.28609 4.46989 7.37278 4.33299 7.48396 4.21734C7.59515 4.10169 7.72854 4.00968 7.87614 3.94683C8.02374 3.88398 8.18251 3.85157 8.34294 3.85156H8.65704C8.81747 3.85157 8.97624 3.88398 9.12384 3.94683C9.27144 4.00968 9.40483 4.10169 9.51601 4.21734C9.6272 4.33299 9.71389 4.46989 9.77088 4.61985C9.82788 4.76981 9.85401 4.92974 9.8477 5.09004L9.69065 9.05582C9.67839 9.36339 9.54758 9.65429 9.32563 9.86758C9.10368 10.0809 8.80781 10.2 8.49999 10.2ZM9.68977 11.9588C9.68977 11.3017 9.15714 10.7691 8.50011 10.7691C7.84307 10.7691 7.31044 11.3017 7.31044 11.9588C7.31044 12.6158 7.84307 13.1484 8.50011 13.1484C9.15714 13.1484 9.68977 12.6158 9.68977 11.9588Z"
                          fill="#FF2147" />
                      </svg>

                      <span
                        class="tw-inline-block tw-align-middle tw-ml-[6px] tw-text-red-500 tw-text-sm tw-whitespace-nowrap">
                        Certifications: {{ qualificationsMissing }} item(s) missing
                      </span>
                    </div>
                  </div>
                </div>

                <div class="tw-flex tw-flex-wrap tw-gap-4 tw-rounded-lg tw-px-4 tw-py-3 tw-bg-white">
                  <v-menu v-for="qualification in campaignQualifications" :key="qualification.key"
                    :close-on-content-click="false" location="top">
                    <template v-slot:activator="{ props }">
                      <VBtn :variant="isQualified(qualification.qualification.id) ?
                        (qualification.qualification.type !== 7 ? 'outlined' : 'flat') : 'outlined'" :color="'primary'"
                        v-bind="props" :ripple="false" class="tw-px-3 tw-rounded-lg tw-border-[#DADADA]"
                        :class="isQualified(qualification.qualification.id) && qualification.qualification.type !== 7 && ' tw-items-stretch tw-bg-[linear-gradient(90deg,_#575EE7_3.71%,_#EF74C0_95.38%)] tw-border-none !tw-p-[1.5px] tw-opacity-100'"
                        :disabled="isQualified(qualification.qualification.id) && qualification.qualification.type !== 7">
                        <div class="tw-flex tw-items-center tw-gap-x-2"
                          :class="isQualified(qualification.qualification.id) && qualification.qualification.type !== 7 && ' tw-bg-white tw-w-full tw-h-full tw-px-3 tw-rounded-[7px]'">
                          <div>
                            <svg v-if="isQualified(qualification.qualification.id)" class="tw-inline-block" width="20"
                              height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M19.1667 8.99999L17.1333 6.68333L17.4167 3.61666L14.4083 2.93333L12.8333 0.283325L10 1.49999L7.16667 0.283325L5.59167 2.93333L2.58334 3.60833L2.86667 6.67499L0.833336 8.99999L2.86667 11.3167L2.58334 14.3917L5.59167 15.075L7.16667 17.725L10 16.5L12.8333 17.7167L14.4083 15.0667L17.4167 14.3833L17.1333 11.3167L19.1667 8.99999ZM8.33334 13.1667L5 9.83333L6.175 8.65833L8.33334 10.8083L13.825 5.31666L15 6.49999L8.33334 13.1667Z"
                                :fill="qualification.qualification.type !== 7 ? '#5723B4' : '#FFFFFF'" />
                            </svg>

                            <svg v-else width="18" height="15" class="tw-inline-block" viewBox="0 0 18 15" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M10.1053 9.10526H9.42105C8.86877 9.10526 8.42105 8.65755 8.42105 8.10526V5.89474C8.42105 5.34245 8.86877 4.89474 9.42105 4.89474H10.1053M10.1053 12.4737H9.26316C8.79808 12.4737 8.42105 12.0967 8.42105 11.6316C8.42105 11.1665 8.79808 10.7895 9.26316 10.7895H10.1053M0.869022 13.499C0.483058 14.1656 0.964115 15 1.73445 15H16.7919C17.5622 15 18.0433 14.1656 17.6573 13.499L10.1286 0.494828C9.74342 -0.170457 8.7829 -0.170458 8.39773 0.494827L0.869022 13.499Z"
                                :fill="'#FFBD3C'" />
                            </svg>
                          </div>

                          <span class=" tw-text-base tw-capitalize tw-tracking-normal"
                            :class="isQualified(qualification.qualification.id) ?
                              (qualification.qualification.type !== 7 ? 'tw-text-regular-text' : ' tw-text-white') : 'tw-text-regular-text'">
                            {{ qualification.qualification.name }}
                          </span>
                        </div>
                      </VBtn>
                    </template>

                    <v-card class="!tw-p-4 !tw-rounded-lg tw-my-2">
                      <div class="tw-flex tw-flex-col tw-gap-y-5 tw-text-sm">
                        <div v-if="[1, 4, 7].includes(qualification.qualification.type)"
                          class="tw-flex tw-flex-col tw-gap-y-3">
                          <template v-if="qualification.qualification.type === 1">
                            <span class="tw-capitalize tw-font-bold tw-text-light-text">
                              Resume Score expression:
                            </span>

                            <code class="tw-p-2 tw-px-3 tw-bg-[#F6F2FF] tw-rounded-md tw-text-xs tw-text-[#5723B4]">
                              {{ getQualificationParameter(qualification.qualification, 'resume_score_expression') }}
                            </code>
                          </template>

                          <template v-if="qualification.qualification.type === 4">
                            <span class="tw-capitalize tw-font-bold tw-text-light-text">
                              VideoAsk link:
                            </span>

                            <a :href="getQualificationParameter(qualification.qualification, 'videoask_link')"
                              target="_blank">
                              <code
                                class="tw-flex tw-gap-x-2 tw-p-2 tw-px-3 tw-bg-[#F6F2FF] tw-rounded-md tw-text-xs tw-text-[#5723B4]">
                              <svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.5933 5.27621C15.092 5.27621 16.3067 4.09517 16.3067 2.63811C16.3067 1.18104 15.092 0 13.5933 0C12.0948 0 10.8799 1.1812 10.8799 2.63811C10.8799 4.09517 12.0946 5.27621 13.5933 5.27621ZM5.40736 5.27621C6.90602 5.27621 8.12076 4.09517 8.12076 2.63811C8.12076 1.18104 6.90602 0 5.40736 0C3.90886 0 2.69395 1.1812 2.69395 2.63811C2.69395 4.09517 3.9087 5.27621 5.40736 5.27621ZM1.03063 8.40315C0.881403 8.39765 0.732825 8.42498 0.595953 8.4831C0.459081 8.54122 0.337439 8.62864 0.240079 8.73884C0.14272 8.84904 0.0721499 8.9792 0.0336217 9.11962C-0.00490654 9.26004 -0.0104009 9.40711 0.0175474 9.54988C0.366903 11.1254 1.12482 12.5876 2.21912 13.7973C3.31343 15.007 4.70769 15.924 6.26915 16.4608C7.8306 16.9977 9.50726 17.1366 11.1395 16.8643C12.7717 16.592 14.3051 15.9175 15.5937 14.9052C17.3191 13.5496 18.5153 11.6596 18.9831 9.55004C19.0104 9.40731 19.0045 9.26044 18.9658 9.12023C18.9271 8.98002 18.8566 8.85006 18.7594 8.73991C18.6622 8.62979 18.5408 8.54234 18.4042 8.48403C18.2676 8.42571 18.1193 8.39802 17.9702 8.40299H1.03047L1.03063 8.40315Z" fill="#5723B4"/>
                              </svg>

                              {{ getQualificationParameter(qualification.qualification, 'videoask_link') }}
                            </code>
                            </a>
                          </template>

                          <template v-if="qualification.qualification.type === 7">
                            <span class="tw-capitalize tw-font-bold tw-text-light-text">
                              Certification Progress:
                            </span>

                            <div class="tw-relative tw-my-5">
                              <v-progress-linear class="tw-mt-3" buffer-value="0" color="primary"
                                :model-value="certificationProgress(qualification.qualification.id)"
                                stream></v-progress-linear>
                              <div
                                class="tw-absolute tw-left-1/2 tw-top-1/2 -tw-translate-x-1/2 -tw-translate-y-1/2 tw-bg-[#F7F6FF] tw-w-12 tw-h-12 tw-rounded-full tw-flex tw-items-center tw-justify-center tw-text-shyftoff-purple tw-font-bold tw-border-4 tw-border-solid tw-border-[#FFF] tw-text-[12px]"
                                :class="{ 'tw-bg-shyftoff-purple tw-text-white': certificationProgress(qualification.qualification.id) === 100 }">
                                {{ certificationProgress(qualification.qualification.id) }}%
                              </div>
                            </div>

                            <button v-if="qualification.qualification.course"
                              @click="onGoToLearnWorldsCourse(qualification.qualification.id)"
                              class="tw-inline-flex tw-items-center tw-justify-between tw-gap-3 tw-rounded-lg tw-leading-[44px] tw-font-semibold tw-text-shyftoff-purple tw-h-11 tw-bg-[#F6F2FF] tw-pl-4 tw-pr-4 hover:tw-bg-[#E7E5FC] tw-cursor-pointer">
                              <span class=" tw-max-w-[80%] tw-line-clamp-1">{{ qualification.qualification.course.name
                                }}</span>
                              <i class="mdi mdi-open-in-new tw-text-lg tw-leading-none"></i>
                            </button>
                          </template>
                        </div>

                        <!-- Qualification Parameter -->
                        <div class="tw-flex tw-flex-col tw-gap-y-3">
                          <span class="tw-capitalize tw-font-bold tw-text-light-text">
                            Qualification Condition:
                          </span>

                          <div
                            class="tw-p-4 tw-bg-[#FFF8EA] tw-text-[13px] tw-text-center tw-rounded-lg tw-relative tw-flex tw-justify-between tw-items-center tw-gap-5 "
                            :class="{ '!tw-bg-[#EAFFF3]': isQualified(qualification.qualification.id) }">
                            <span v-if="isAdminRole"
                              class="tw-font-extrabold tw-uppercase tw-grow tw-shrink-0 tw-text-left tw-max-w-[80%] tw-line-clamp-2">
                              {{ getQualificationParameter(qualification.qualification, 'qualification_condition') }}
                            </span>

                            <div v-if="!isQualified(qualification.qualification.id)">
                              <svg class="tw-inline-block tw-align-middle -tw-mt-1 !tw-ml-auto" width="19" height="16"
                                viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M9.86561 10.1053H8.22135V5.89474H9.86561M9.86561 13.4737H8.22135V11.7895H9.86561M0 16H18.087L9.04348 0L0 16Z"
                                  fill="#FFBD3C" />
                              </svg>
                              <span class="tw-text-[#B98621] tw-text-sm tw-ml-1">Not met</span>
                            </div>

                            <div v-else>
                              <svg class="tw-inline-block tw-align-middle !tw-ml-auto" width="18" height="18"
                                viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_2078_78)">
                                  <path
                                    d="M9 1.5C4.86 1.5 1.5 4.86 1.5 9C1.5 13.14 4.86 16.5 9 16.5C13.14 16.5 16.5 13.14 16.5 9C16.5 4.86 13.14 1.5 9 1.5ZM7.5 12.75L3.75 9L4.8075 7.9425L7.5 10.6275L13.1925 4.935L14.25 6L7.5 12.75Z"
                                    fill="#4C986D" />
                                </g>
                                <defs>
                                  <clipPath id="clip0_2078_78">
                                    <rect width="18" height="18" fill="white" />
                                  </clipPath>
                                </defs>
                              </svg>
                              <span v-if="!isAdminRole" class="tw-text-[#4C986D] tw-text-sm tw-ml-1">Passed</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </v-card>
                  </v-menu>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <template v-if="campaignDetails?.support_material_url">
        <v-alert v-model="alert" border="start" variant="flat" color="#E7F2FD" border-color="#2096f3"
          icon="mdi-information" class="tw-my-8 !tw-text-shyftoff-purple">
          <h3>{{ campaignDetails?.name }} Knowledge Center</h3>

          <p class="mb-2">ShyftOff Knowledge Base with key information for certification and taking calls.</p>

          <a :href="campaignDetails?.support_material_url" target="_blank"
            class="tw-inline-flex tw-items-center tw-rounded-lg tw-leading-[44px] tw-font-semibold tw-text-white tw-h-11 tw-bg-shyftoff-purple tw-px-4 tw-gap-3 hover:tw-opacity-70 tw-cursor-pointer">
            <span>{{ campaignDetails?.name }} Support Materials</span>
            <i class="mdi mdi-open-in-new tw-text-lg tw-leading-none"></i>
          </a>
        </v-alert>
      </template>

      <div v-if="profileStore.getProfile.groups.includes('Admins') && campaignDetails">
        <VCard class="!tw-px-0 rounded-lg">
          <WIZZYWIGEditor v-model="campaignDetails.wysiwyg_description" />
          <div class="tw-flex tw-gap-x-4 tw-items-center tw-justify-end tw-mt-6 tw-px-6">
            <VBtn @click="saveContentBlock" :loading="loadingWizzywigContent" color="primary" size="large" flat
              rounded="lg" class="px-7 tw-px-7 tw-font-semibold tw-text-sm tw-min-w-[200px]">
              Save
            </VBtn>
          </div>
        </VCard>
      </div>

      <template v-if="campaignProperties.content_blocks && contentBlocks">
        <div v-for="(contentBlock, index) in contentBlocks" :key="index">
          <v-alert v-model="alert" border="start" variant="flat" color="#F7F6FF" border-color="#5723B4"
            class="tw-my-10 !tw-border-[#F7F6FF]">
            <div v-html="contentBlock" />
          </v-alert>
        </div>
      </template>

    </div>
    <!-- QBO Static Div  -->
    <div v-if="campaignDetails != null && (campaignDetails.id === 10 || campaignDetails.id === 14)">
      <HtmlBlock title="Track & Calculate your NPS Score" linkText="NPS Calculator"
        link="https://docs.google.com/spreadsheets/d/1u0kU8z_V8KOpSNZhsRvEQ4vf03YXN6DNHnp4NN2Zgrs/edit#gid=0">
        <p>From Salesforce, you are able to track your survey responses and their scores. Using those counts you will
          get
          your <b>NPS Score</b>.</p>
        <p>Use calculator link below to figure out your <b>NPS Score</b>.</p>
        <p>Video instructions are in the pink cell on the calculator page for how to find the survey counts in
          Salesforce.
        </p>
      </HtmlBlock>
      <HtmlBlock title="Welcome to QuickBooks">
        <p>On Friday, August 4th we were able to request your credentials. We hope to have them back by the middle of
          next
          week, and will keep you updated of any news.</p>
        <h3>Training Overview</h3>
        <Table :tableHeaders="['Certification length', 'Certification bonus', 'Cert bonus timing']"
          :tableContent="['Estimated 30 hours (Revised)', '$300', '$150 after 20 productive hours, final $150 after 40 total productive hours']" />
        <h3>Campaign Pay Rates</h3>
        <Table :tableHeaders="['Base Rate', 'Level 2']"
          :tableContent="['$15 per productive hour', '$17 per productive hour when over 15 hours in the week. Applies to all hours worked.']" />
      </HtmlBlock>
      <HtmlBlock title="Performance Targets">
        <p>Intuit has strict performance targets that we must get to as we complete nesting. Nesting is typically
          considered 50 calls. We will be there to support you and provide resources as you become experienced at taking
          the new calls.</p>
        <h3>Training Overview</h3>
        <Table :tableHeaders="['NPS Goal', 'Issue Resolution', 'AHT', 'Transfer Rate']"
          :tableContent="['60%', '90%', '25 minutes', 'Below 10%']" />
      </HtmlBlock>
    </div>
    <!-- NRTC Static Div  -->
    <div v-if="campaignDetails != null && campaignDetails.id === 4">
      <HtmlBlock title="Being A Successful NRTC Agent"
        link="https://docs.google.com/document/d/18LsZ4E8T9wpP6vIIIfJnjXoUGcf2cvRCu8obgaZknO0/"
        linkText="Being Successful">
        <p>Click on the link below to learn how to be a successful NRTC agent.</p>
      </HtmlBlock>
    </div>
    <v-card class="tw-bg-white tw-text-regular-text tw-pb-0 tw-mt-6 tw-relative">

      <div v-if="campaignDetails?.description && campaignProperties.description" class="tw-relative tw-z-[3] tw-mb-8">
        <h3 class="tw-font-extrabold tw-mb-3">Description</h3>
        <p class="tw-leading-[1.6]">{{ campaignDetails?.description }}</p>
      </div>

      <div v-if="payweekHours || payweekPay">
        <ul
          class="tw-grid tw-grid-cols-12 tw-justify-items-end lg:tw-gap-x-6 tw-gap-y-4 tw-text-center tw-relative tw-z-50">
          <li
            class="tw-bg-[#F6F0FF] tw-flex tw-flex-col tw-items-start tw-rounded-lg tw-justify-start tw-col-span-12 lg:tw-col-span-6 tw-py-3 tw-px-6 tw-w-full tw-space-y-1">
            <span class="tw-grow-0 tw-opacity-70">
              Payweek Hours
            </span>
            <span class="tw-font-black tw-text-xl xl:tw-text-2xl tw-grow-0 tw-tracking-wide">
              {{ payweekHours.toFixed(2) }}
            </span>
          </li>
          <li
            class="tw-bg-[#F3F2FF] tw-flex tw-flex-col tw-items-start tw-rounded-lg tw-justify-start tw-col-span-12 lg:tw-col-span-6 tw-py-3 tw-px-6 tw-w-full tw-space-y-1">
            <span class="tw-grow-0 tw-opacity-70">
              Payweek Pay
            </span>
            <span class="tw-font-black tw-text-xl xl:tw-text-2xl tw-grow-0 tw-tracking-wide">
              ${{ payweekPay.toFixed(2) }}
            </span>
          </li>
        </ul>
      </div>
      <div>
        <div v-if="!loading && campaignPayroll.length">
          <v-table density="comfortable" class="tw-mt-6 tw-bg-transparent">
            <thead>
              <tr>
                <th class="tw-uppercase !tw-font-bold tw-w-1/4 tw-text-light-text">
                  Date
                </th>
                <th class="tw-uppercase !tw-font-bold tw-w-1/4 tw-text-light-text">
                  End Date
                </th>
                <th class="tw-uppercase !tw-font-bold tw-w-1/4 tw-text-light-text">
                  Hours
                </th>
                <th class="tw-uppercase !tw-font-bold tw-w-1/4 tw-text-light-text">
                  Amount
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(payment, index) in campaignPayroll" :key="index" class="tw-text-base">
                <td> {{ subtractDays(payment.pay_date, 6).toLocaleDateString('en-US') }} </td>
                <td> {{ dateWithoutTimezone(payment.pay_date).toLocaleDateString('en-US') }} </td>
                <td> {{ payment.hours?.toFixed(2) }} </td>
                <td> ${{ payment.amount }} </td>
              </tr>
            </tbody>
          </v-table>
        </div>
        <div v-else-if="loading" class="tw-mt-8 tw-mb-6">
          <v-skeleton-loader type="table-thead">
          </v-skeleton-loader>
          <v-skeleton-loader type="table-tbody">
          </v-skeleton-loader>
        </div>
      </div>
      <VSnackbar v-model="showNotification" :timeout="6000" color="green-darken-2">
        Content saved.
      </VSnackbar>
    </v-card>
  </div>
</template>
<style scoped lang="scss">
::v-deep(.v-alert) {
  @apply tw-rounded-lg tw-border tw-border-[#EAE8FF] tw-border-solid tw-border-l-0;

  .v-alert__border {
    @apply tw-opacity-100;
  }

  .v-alert__content {
    @apply tw-text-[#403870] tw-leading-5;

    h1,
    h2,
    h3,
    h4 {
      @apply tw-text-shyftoff-purple tw-mb-3;
    }

    h1,
    h2 {
      @apply tw-font-extrabold tw-text-lg;
    }

    h3 {
      @apply tw-font-extrabold tw-mt-6;

      &:first-child {
        @apply tw-mt-0;
      }
    }

    img {
      @apply tw-mix-blend-darken;
    }

    table {
      width: 100%;
      border-spacing: 0;
      margin-top: 0.75rem;

      tbody {
        tr {
          td {
            @apply tw-py-4 tw-leading-6 tw-text-base;
          }

          &:first-child {
            td {
              @apply tw-uppercase tw-font-extrabold tw-text-[#8C70BE] tw-border-b tw-border-solid tw-border-[#EDEDED] tw-h-12;
            }
          }
        }
      }
    }
  }
}

.policy {
  @apply tw-text-sm;

  p {
    @apply tw-my-4 tw-text-darker-light-text;

    &:last-child {
      @apply tw-mb-0;
    }
  }
}

::v-deep(.v-skeleton-loader__table-thead) {
  @apply tw-flex-nowrap;

  >div {
    @apply tw-max-w-[120px] tw-mx-auto tw-flex-shrink;
  }
}

.v-chip {
  @apply tw-rounded-lg tw-px-1 tw-border-[#DADADA] tw-mr-3 hover:tw-border-[#d1b9fc];

  :deep(.v-chip__overlay) {
    @apply tw-opacity-0 #{!important};
  }

  &:hover {
    :deep(.v-chip__overlay) {
      @apply tw-opacity-0 #{!important};
    }
  }
}

.v-list .v-btn {
  @apply tw-rounded-lg tw-border-[#DADADA] hover:tw-border-[#C6C6C6];

  :deep(.v-btn__overlay) {
    // @apply tw-opacity-0 #{!important};
  }
}

.v-table {
  tr {
    td {
      &:last-child {
        .v-list-item {
          .v-btn {
            :deep(.v-btn__loader) {
              >div {
                @apply tw-w-4 tw-h-4 #{!important};
              }
            }
          }
        }
      }
    }
  }
}

header {
  ::v-deep(.v-btn) {
    // grid-template-areas: "content";
    grid-template-columns: 32px 1fr 12px;

    &[aria-expanded="true"] {
      @apply tw-bg-white #{!important};

      .v-btn__prepend {
        >div {
          @apply tw-border-shyftoff-purple/40;
        }

        svg {
          @apply tw-fill-shyftoff-purple #{!important};
        }
      }

      .v-btn__content {
        span {
          @apply tw-text-regular-text #{!important};
        }
      }

      .v-btn__prepend,
      .v-btn__append {
        @apply tw-mix-blend-multiply;

        svg {
          @apply tw-fill-shyftoff-purple;
        }
      }
    }

    .v-btn__prepend,
    .v-btn__append {
      @apply tw-m-0 #{!important};

      svg {
        @apply tw-fill-light-text;
      }
    }

    .v-btn__content {
      @apply tw-text-left tw-overflow-hidden tw-text-ellipsis;

    }
  }
}

:deep(.v-overlay__content) {
  @apply tw-w-full sm:tw-w-auto #{!important};
}

:deep(.tox) {
  @apply tw-border-none;
}
</style>
